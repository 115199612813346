import { useRef, useEffect } from 'react'
import { connect } from 'react-redux'
import get from 'lodash/get'
import { BookDivingCard } from './BookDivingCard'
import Slider from 'react-slick'
import { pushToDataLayer } from 'gtm'
import ViewAllCard from '../sliders/cards/ViewAllCard'
import AdventureCardPlaceholder from '../../adventures/AdventureCardPlaceholder'
import PropTypes from 'prop-types'

import { isMobile } from '../../../utils'

const placeholderItems = [...new Array(4)]

const BookDivingSlider = ({
  data,
  tab,
  selectedCurrency,
  getButtonMessage,
  getSearchUrl,
  setParams,
  params,
  total,
  isLoading,
  isShowPlaceholder,
}) => {
  let ref = useRef()
  let curretnTab = useRef(tab)
  const settings = {
    slidesToShow: isMobile() ? 2 : 4,
    slidesToScroll: isMobile() ? 1 : 4,
    infinite: false,
    dots: true,
    responsive: [
      { breakpoint: 992, settings: { slidesToShow: 2, slidesToScroll: 3 } },
      {
        breakpoint: 768,
        settings: { slidesToShow: 3, slidesToScroll: 1, arrows: false },
      },
      {
        breakpoint: 576,
        settings: { slidesToShow: 2.25, slidesToScroll: 1, arrows: false },
      },
      {
        breakpoint: 480,
        settings: { slidesToShow: 1.25, slidesToScroll: 1, arrows: false },
      },
    ],
    lazyLoad: 'ondemand',
    beforeChange: beforeChangeHandler,
  }

  function beforeChangeHandler(current, next) {
    if(!ref?.props) {
      return
    }
    const { props } = ref
    const lastSlide = Math.ceil(
      props?.children?.[0]?.length / props?.slidesToScroll,
    )
    const isOneSlidesBefore =
      Math.abs(lastSlide - next / props?.slidesToScroll) <= 1
    const isShowNext = next > current
    if(
      isShowNext &&
      isOneSlidesBefore &&
      props?.children?.[0]?.length <= total
    ) {
      ref.slickGoTo(params.page)
      setTimeout(() => {
        setParams((prevState) => ({ page: prevState.page + 1, pageSize: 4 }))
      }, 500)
    }
    const difference = current + Math.abs(next - current)
    pushToDataLayer({
      event: 'impression',
      ecommerce: {
        currencyCode: selectedCurrency,
        impressions: data
          .slice(current, difference)
          .map((item) => item.impression),
      },
    })
  }

  useEffect(() => {
    const numberOfItemsToShow = get(ref, 'innerSlider.props.slidesToShow')
    if(numberOfItemsToShow) {
      pushToDataLayer({
        event: 'impression',
        ecommerce: {
          currencyCode: selectedCurrency,
          impressions: data
            .slice(0, numberOfItemsToShow)
            .map((item) => item.impression),
        },
      })
      // scroll to first slide when user change tabs
      if(curretnTab.current !== tab) {
        ref.slickGoTo(0)
        curretnTab.current = tab
      }
    }
  }, [ref.current, data.length, tab])

  return (
    <Slider
      className={`slick-pt-custom same-height space-between-small ${
        isLoading ? 'placeholder-animation' : ''
      }`}
      {...settings}
      ref={(slider) => (ref = slider)}
    >
      {isShowPlaceholder
        ? placeholderItems.map((_, i) => {
          return <AdventureCardPlaceholder key={i} />
        })
        : data?.map((item) => (
          <BookDivingCard
            key={item.id || item.shopId || item.activityId || item.pk}
            item={item}
            tab={tab}
          />
        ))}
      {tab === 'dive-centers' && (
        <ViewAllCard
          tab={tab}
          getButtonMessage={getButtonMessage}
          getSearchUrl={getSearchUrl}
        />
      )}
    </Slider>
  )
}

export default connect((state) => ({
  selectedCurrency: state.selectedCurrency,
}))(BookDivingSlider)

BookDivingSlider.propTypes = {
  data: PropTypes.array,
  tab: PropTypes.string,
  selectedCurrency: PropTypes.string,
  getButtonMessage: PropTypes.func,
  getSearchUrl: PropTypes.func,
  setParams: PropTypes.func,
  params: PropTypes.object,
  total: PropTypes.number,
  isLoading: PropTypes.bool,
  isShowPlaceholder: PropTypes.bool,
  slidesToScroll: PropTypes.number,
}
