import get from 'lodash/get'
import { dummyImagePath } from 'config'
import { target } from 'utils'

export const DiveSiteSliderCard = ({ item }) => {
  const picture = get(item, 'thumbnails.SmallDesktop', dummyImagePath)
  return (
    <div className='operator-item-wrapper'>
      <a
        className='operator-item-card bordered-element operator-item-card-container'
        href={item.url}
        target={target}
        data-navigation-elements='data-navigation-elements'
        data-region-identifier='book-diving'
      >
        <div className='img-wrapper ratio-16-9'>
          <span
            style={{
              backgroundImage: `url(${picture})`,
            }}
            className='img'
          />
        </div>
        <div className='operator-item-card__content'>
          <div className='operator-item-card__title'>{item.title}</div>
          <ul className='features'>
            <li>
              <i className='font-icons location-icon' />
              {item.locationTitle}
            </li>
            <li>
              <i className='font-icons icon-dive-sites' />
              {item.diveSiteType && item.diveSiteType.join(', ')}
            </li>
          </ul>
        </div>
      </a>
    </div>
  )
}
