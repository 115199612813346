import { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import '../styles/map-container.scss'

export default function WhereToDiveData({ info, regionIdentifier }) {
  return (
    <Fragment>
      {!!info.numberDiveShops && (
        <span className='guide-map-popup__description'>
          <FormattedMessage
            id='number_of_dive_shops'
            defaultMessage='{ qty, plural, one {# dive shop} other {# dive shops}}'
            values={{
              qty: info.numberDiveShops,
            }}
          />
        </span>
      )}
      {!!info.numberDiveSites && (
        <span className='guide-map-popup__description'>
          <FormattedMessage
            id='number_of_dive_sites'
            defaultMessage='{ qty, plural, one {# dive site} other {# dive sites}}'
            values={{
              qty: info.numberDiveSites,
            }}
          />
        </span>
      )}
      {info.travelUrl && (
        <a
          className='btn-guides-blue'
          href={info.travelUrl}
          data-navigation-elements='data-navigation-elements'
          data-region-identifier={regionIdentifier}
        >
          <FormattedMessage id='view_details' defaultMessage='View Details' />
        </a>
      )}
    </Fragment>
  )
}
