export default function AdventureCardPlaceholder() {
  return (
    <div className='adventure-card-placeholder'>
      <div className='img-wrapper' />

      <div className='info'>
        <strong className='adventure-card__title' />
        <p className='dive-center-title' />
        <p className='adventure-card__inclusion' />
        <p className='adventure-card__availability' />
        <div className='adventure-card__price'>
          <span className='old-pricing' />
          <span className='pricing' />
        </div>
      </div>
    </div>
  )
}
