import { routes } from '../../localized-urls'

export function getLanguageParam(currentLang = 'en') {
  const supportedLanguages = Object.keys(routes)

  if(supportedLanguages.includes(currentLang)) {
    return {}
  }

  return { from_lang: currentLang }
}

export const autosuggestStyles = {
  control: (styles) => ({
    ...styles,
    borderRadius: 2,
    border: '1px solid #dde3e6',
    backgroundColor: '#fff',
    height: '38px',
  }),
  valueContainer: (styles) => ({
    ...styles,
  }),
  clearIndicator: (styles) => ({
    ...styles,
    cursor: 'pointer',
  }),
  menu: (styles) => ({
    ...styles,
    zIndex: 9999,
  }),
  option: (styles, state) => {
    return {
      ...styles,
      backgroundColor: state.isFocused ? '#F4F8FB' : null,
      color: state.isFocused ? '#002547' : null,
      '&:active': {
        backgroundColor: '#fff',
        color: '#0070D3',
      },
    }
  },
}

export const getAutosuggestStyle = (height = '38px') => {
  return {
    control: (styles) => ({
      ...styles,
      borderRadius: 2,
      border: '1px solid #dde3e6',
      backgroundColor: '#fff',
      height,
    }),
    valueContainer: (styles) => ({
      ...styles,
    }),
    clearIndicator: (styles) => ({
      ...styles,
      cursor: 'pointer',
    }),
    menu: (styles) => ({
      ...styles,
      zIndex: 9999,
    }),
    option: (styles, state) => {
      return {
        ...styles,
        backgroundColor: state.isFocused ? '#F4F8FB' : null,
        color: state.isFocused ? '#002547' : null,
        '&:active': {
          backgroundColor: '#fff',
          color: '#0070D3',
        },
      }
    },
  }
}

export function isBookableShop(
  { bookingUrl, travelUrl, affiliateId, diveSiteId },
  isDiveSite
) {
  if(bookingUrl || (isDiveSite && travelUrl)) {
    return {
      travelShop: true,
      data: {
        'data-bookable-shop': true,
        'data-shop-id': affiliateId,
        'data-site-id': diveSiteId,
      },
    }
  }
  return {
    travelShop: false,
    data: {},
  }
}
