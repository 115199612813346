import { FormattedMessage } from 'react-intl'
import styles from './Cards.module.scss'

const ViewAllCard = ({ getSearchUrl, tab }) => {
  return (
    <a
      className={styles.viewAllLink}
      target='_blank'
      href={getSearchUrl(tab)}
      data-navigation-elements='data-navigation-elements'
      data-region-identifier='book-diving'
    >
      <div className={styles.viewAllWrapper}>
        <FormattedMessage
          id='dive-shops-view-all-btn'
          defaultMessage='View all'
        />
      </div>
    </a>
  )
}

export default ViewAllCard
