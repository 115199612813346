import { useState } from 'react'
import ReactPlayer from 'react-player'
import Modal from 'react-modal'
import Slider from 'react-slick'

export default function VideoPopup({ videos }) {
  let videosId = {}

  videos.forEach((video) => {
    videosId[video.id] = false
  })

  const [isOpen, triggerPopup] = useState(false)
  const [playing, triggerPlay] = useState(videosId)
  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    dots: true,
    arrows: true,
  }

  const youtubeVideoBlocks = document.querySelectorAll('[youtube-video]')

  if(youtubeVideoBlocks && youtubeVideoBlocks.length) {
    youtubeVideoBlocks.forEach((item) => {
      item.addEventListener('click', function() {
        triggerPopup(true)
      })
    })
  }

  return (
    <Modal
      isOpen={isOpen}
      style={{ content: { maxWidth: '730px' } }}
      onRequestClose={() => triggerPopup(false)}
      ariaHideApp={false}
      className='Modal react-popup video-popup'
      overlayClassName='Overlay'
      contentLabel='Booking enquire popup'
    >
      <span className='close-btn' onClick={() => triggerPopup(false)} />
      <Slider
        className='slick-pt-custom'
        {...settings}
        beforeChange={() => triggerPlay(videosId)}
      >
        {videos.map((video, index) => {
          return (
            <ReactPlayer
              url={video.url}
              key={video.id || `video_${index}`}
              width={
                window.innerWidth < 768
                  ? `${window.innerWidth * 0.85}px`
                  : '640px'
              }
              controls
              onPlay={() => triggerPlay({ ...videosId, [video.id]: true })}
              onStart={() => triggerPlay({ ...videosId, [video.id]: true })}
              playing={playing[video.id]}
            />
          )
        })}
      </Slider>
    </Modal>
  )
}
