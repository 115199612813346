import { useState } from 'react'
import Map from './Map'
import GuideMapPopup from './GuideMapPopup'
import '../styles/where-to-dive.scss'
import NoResultsMap from './NoResultsMap'
import { MapContainer } from 'react-leaflet'
import { isMainLand } from '../../../config'
import { isMobile } from '../../../utils'
import useMapInitialBounds from '../../../hooks/useMapInitialBounds'

const isMobileDevice = isMobile()
export default function WhereToDive({
  locationBounds,
  pins,
  isTravelMap,
  showWidget,
  isDiveCenter,
  countryCode,
}) {
  const [selectedPin, setSelectedPin] = useState()
  const { initBounds } = useMapInitialBounds(locationBounds, pins)
  const handlePinClick = (pin) => setSelectedPin(pin)
  const closePopup = () => setSelectedPin(null)

  return (
    <div className='where-to-dive'>
      {selectedPin && (
        <GuideMapPopup
          info={selectedPin}
          handleCloseClick={closePopup}
          showActionButton={selectedPin.isPermanent}
          isWhereToDive
          showWidget={showWidget}
          isTravelMap={isTravelMap}
          regionIdentifier='where-to-dive-map'
        />
      )}
      {isDiveCenter && pins.length <= 1 && <NoResultsMap />}
      <MapContainer
        maxZoom={18}
        minZoom={isMainLand(countryCode) ? 2 : 1}
        zoomControl={false}
        scrollWheelZoom={false}
        tap={!isMobileDevice}
        touchZoom={isMobileDevice}
        dragging={!isMobileDevice}
        bounds={initBounds}
        zoom={14}
      >
        <Map
          pins={pins}
          onPinClick={handlePinClick}
          selectedPinItem={selectedPin}
          locationBounds={initBounds}
          pinType={isTravelMap && 'diveSite'}
          incapsulatedMap
          preventCentering
          onMapClick={closePopup}
          isDiveCenter={isDiveCenter}
          countryCode={countryCode}
        />
      </MapContainer>
    </div>
  )
}
