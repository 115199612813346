import { useMemo } from 'react'
import {
  LiveaboardSliderCard,
  ResortSliderCard,
  AdventureSliderCard,
  DiveShopSliderCard,
  DiveSiteSliderCard,
} from '../sliders/cards'

export const BookDivingCard = ({ item, tab }) =>
  useMemo(() => {
    switch (tab) {
      case 'liveaboards':
        return <LiveaboardSliderCard item={item} />
      case 'resorts':
        return <ResortSliderCard item={item} />
      case 'dive-centers':
        return <DiveShopSliderCard item={item} tab={tab} />
      case 'dive-sites':
        return <DiveSiteSliderCard item={item} />
      case 'courses':
      case 'snorkeling':
      case 'dive-trips':
      case 'conservation':
        return <AdventureSliderCard item={item} tab={tab} />
    }
  }, [tab])
