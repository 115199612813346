import { useIntl } from 'react-intl'
import cx from 'classnames'
import styles from './Buttons.module.scss'

const Button = ({ elem, activeTab, onClick }) => {
  const intl = useIntl()
  const handleClick = () => {
    onClick(elem.id)
  }

  return (
    <button
      onClick={handleClick}
      id={elem.id}
      className={cx(styles.button, { [styles.active]: activeTab === elem.id })}
    >
      {elem.icon && (
        <i
          className={cx(
            styles.buttonIcon,
            styles[elem.icon],
            `font-icons ${elem.icon}`
          )}
        />
      )}
      {intl.formatMessage(elem.title)}
    </button>
  )
}

export default Button
