import { useState, useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import { TravelSearch } from './search-autosuggest'
import { connect } from 'react-redux'
import { showLoader } from '../../actions/main-actions'
import './hero-search.sass'

const HeroSearch = ({
  searchLaUrl,
  isDiveGuides,
  isConservation,
  worldUrl,
  showLoader,
  pageLoader,
}) => {
  const searchInputRef = useRef()
  const [activeMobileDestinations, setMobileDestinationsVisible] = useState(
    false,
  )

  const [selectedUrl, setSelectedUrl] = useState('')

  const onLocationFound = (url) => {
    setSelectedUrl(url)
  }
  const handleSubmit = () => {
    if(!selectedUrl && (isDiveGuides || isConservation)) {
      // on dive guides pages we show no results without redirecting
      searchInputRef.current.showResults()
      return
    }

    if(!pageLoader) {
      // if the location was found - we redirect on click to this location
      if(searchLaUrl || selectedUrl) {
        showLoader(true)
        window.location.href = selectedUrl || searchLaUrl
      }
    }
  }

  const toggleMobileDestinations = (close) => {
    setMobileDestinationsVisible(close)
  }

  return (
    <div
      data-testid='hero-container'
      className={`hero-search ${pageLoader ? 'placeholder-animation' : ''}${
        activeMobileDestinations ? ' mobile-destination' : ''
      }`}
    >
      <div className='hero-search__field'>
        <TravelSearch
          // popupId='country-list-popup'
          handlePopupItems
          ref={searchInputRef}
          isDiveGuides={isDiveGuides}
          isConservation={isConservation}
          onDestinationPopupToggle={toggleMobileDestinations}
          setRedirectUrl={onLocationFound}
          worldUrl={worldUrl}
        />
      </div>
      <button
        className='btn-red no-uppercase'
        onClick={handleSubmit}
        disabled={pageLoader}
      >
        <FormattedMessage id='search' defaultMessage='Search' />
      </button>
    </div>
  )
}

const mapStateToProps = (state) => ({ pageLoader: state.pageLoader })
const mapDispatchToProps = { showLoader }

export default connect(mapStateToProps, mapDispatchToProps)(HeroSearch)
