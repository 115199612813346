import get from 'lodash/get'
import API from 'api'
import { useEffect, useState } from 'react'
import { dummyImagePath } from 'config'
import { target } from 'utils'

export const DiveShopSliderCard = ({ item }) => {
  const [image, setImage] = useState('')

  useEffect(() => {
    let controller
    let signal

    if('AbortController' in window) {
      controller = new window.AbortController()
      signal = controller.signal
    }

    API(`search/dive-centers/${item.pk}/images/`, signal)
      .get()
      .then((res) => {
        const url = get(res.results, '[0].picture.medium', dummyImagePath)
        setImage(url)
      })

    return () => controller && controller.abort() // abort on unmount
  }, [])

  return (
    <div className='operator-item-wrapper'>
      <a
        className='operator-item-card bordered-element operator-item-card-container'
        href={item.url}
        data-navigation-elements='data-navigation-elements'
        data-region-identifier='book-diving'
        target={target}
      >
        <div className='img-wrapper ratio-16-9'>
          <span
            style={{
              backgroundImage: `url(${image})`,
            }}
            className='img'
          />
        </div>
        <div className='operator-item-card__content'>
          <div className='operator-item-card__title'>{item.title}</div>
          <ul className='features'>
            <li>
              <i className='font-icons location-icon' />
              {item.locationTitle}, {item.countryTitle}
            </li>
            <li>
              <i className='font-icons icon-star-outline' />
              {item.starLabel}
            </li>
          </ul>
        </div>
      </a>
    </div>
  )
}
