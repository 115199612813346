import Button from './Button'
import ShopTypesDivider from '../ShopTypesDivider'
import styles from './Buttons.module.scss'

export const Buttons = ({ tabs, activeTab, onClick }) => {
  const buttons = tabs.map((elem) => (
    <Button key={elem.id} elem={elem} activeTab={activeTab} onClick={onClick} />
  ))

  const shopBtns = [
    ...buttons.slice(0, 4),
    buttons.length > 4 ? <ShopTypesDivider dividerClass='sliderDivider' /> : '',
    ...buttons.slice(4),
  ]

  return <div className={styles.buttonsGroup}>{shopBtns}</div>
}
