import { Fragment, useMemo, useState } from 'react'
import moment from 'moment'
import cx from 'classnames'

const monthes = moment.localeData('en').months()

const CalendarContentRow = ({ element }) => {
  const define = (prop) => {
    if(prop === 10) {
      return <div className='point' />
    } else if(prop === 20) {
      return <div className='point d50' />
    } else {
      return <div className='point d100' />
    }
  }

  return (
    <div className='calendar-container-item__content'>
      <div className='calendar-container-item__content-title'>
        {element.title}
      </div>
      <div className='calendar-container-item__content-row'>
        {monthes.map((month) => (
          <div key={month} className='calendar-container-item__content-col'>
            {define(element[month.toLowerCase()])}
          </div>
        ))}
      </div>
    </div>
  )
}

export const CalendarGroupItem = ({ group }) => {
  const [isToggled, setToggled] = useState(false)
  const isSingle = group.length === 1
  const handleClick = () => {
    if(!isSingle) {
      setToggled(!isToggled)
    }
  }
  const totalRow = useMemo(() => {
    return group.reduce((acc, item) => {
      monthes.forEach((m) => {
        const month = m.toLowerCase()
        if(item[month] > acc[month] || !acc[month]) {
          acc[month] = item[month]
        }
      })
      acc.group = item.group
      return acc
    }, {})
  }, [group])

  return (
    <div
      className={cx('calendar-container-item', {
        one: isSingle,
        open: isToggled && !isSingle,
      })}
    >
      {group.map((element, index) => {
        return (
          <div
            className='calendar-container-item__row'
            key={element.group.id + index}
          >
            {index === 0 && (
              <Fragment>
                <div
                  data-testid='row-btn'
                  className='calendar-container-item__button'
                  onClick={handleClick}
                >
                  {element.group.title}
                </div>
                {totalRow && !isToggled && !isSingle && (
                  <CalendarContentRow element={totalRow} />
                )}
              </Fragment>
            )}
            <CalendarContentRow element={element} />
          </div>
        )
      })}
    </div>
  )
}
