import { useEffect, useState, useMemo } from 'react'
import API from '../../../../api'
import get from 'lodash/get'
import { convertCurrency } from '../../../helpers'
import { useSelector } from 'react-redux'
import { parseQuery } from '../../../../utils'

const imperessionCategoryMapping = {
  resorts: 'Dive Resort',
  liveaboards: 'Liveaboard',
  'dive-trips': 'Diving (Adventures)',
  courses: 'Courses (Adventures)',
  snorkeling: 'Snorkeling (Adventures)',
}

const queryParams = parseQuery() || {}

const useDiveItems = (
  isDiveSite,
  location,
  selectedCurrency,
  inViewport,
  enterCount,
) => {
  const [isLoading, setLoading] = useState(true)
  const selectedTab = useSelector((state) => state.selectedTab)
  const [params, setParams] = useState({ pageSize: 8, page: 1 })
  const [data, setData] = useState({ tab: selectedTab, results: [] })
  const [isShowPlaceholder, setShowPlaceholder] = useState(true)
  let controller
  let signal

  const API_URL = useMemo(() => {
    if(selectedTab === 'dive-centers') {
      return `dive-guide/${location.type}/${location.id}/${selectedTab}/`
    }
    const baseUrl = selectedTab === 'conservation' ? '/api/adventure/v1/' : ''
    return `${baseUrl}search/${location.type}/${
      isDiveSite ? 'all' : location.id
    }/${selectedTab}/`
  }, [isDiveSite, location, selectedTab])

  useEffect(() => {
    setParams({ pageSize: 8, page: 1 })
    setData({ tab: selectedTab, results: [] })
    setShowPlaceholder(true)
  }, [selectedTab])

  useEffect(() => {
    getDiveItems()
    return () => controller && controller.abort()
  }, [selectedTab, inViewport, params])

  function getDiveItems() {
    if(!inViewport && enterCount === 0) {
      return { data }
    }
    setLoading(true)
    if('AbortController' in window) {
      controller = new window.AbortController()
      signal = controller.signal
    }
    API(API_URL, signal)
      .get({
        dive_site_ids: location.diveSiteId,
        shop_id: queryParams.shop_id,
        page_size: params.pageSize,
        page: params.page,
        ordering:
          selectedTab === 'dive-centers'
            ? '-multiple_five_star,-dsl_points,random'
            : '',
      })
      .then((res) => {
        if(!res || !res.results) {
          return
        }

        let impressions = []
        const isTravelProducts = ['resorts', 'liveaboards'].includes(
          selectedTab,
        )
        const isProducts = !['dive-sites', 'dive-centers'].includes(
          selectedTab,
        )
        res.results.forEach((item, index) => {
          if(isProducts) {
            const shopTitle = get(item, 'diveCenter.title')
            const affiliateId = get(item, 'diveCenter.affiliateId')
            const price = get(
              item,
              'price.price',
              item.minimumPrice || item.priceSum,
            )
            const currency = get(
              item,
              'price.currency',
              item.currency || 'USD',
            )
            const convertedPrice = price
              ? convertCurrency(
                price,
                currency,
                selectedCurrency,
                false,
                !isTravelProducts,
              )
              : 0
            const impression = {
              name: item.titleEn || item.title,
              id: item.activityId || item.shopId || item.pk || item.id,
              price: convertedPrice.toFixed(2),
              brand: `${shopTitle}|${affiliateId}`,
              category: imperessionCategoryMapping[selectedTab],
              list: window.currentPageTypeAdventures,
              position: index + 1,
            }
            impressions.push(impression)
            // Path object to cards
            item.impression = impression
          }
        })

        setData((prevState) => ({
          ...prevState,
          tab: selectedTab,
          results: [...prevState.results, ...res.results],
          total: res.count,
        }))
        setShowPlaceholder(false)
        setLoading(false)
      })
  }

  return { data, isLoading, setParams, params, isShowPlaceholder }
}

export default useDiveItems
