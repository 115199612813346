import { useMemo, useRef, useEffect } from 'react'
import { connect } from 'react-redux'
import Slider from 'react-slick'
import get from 'lodash/get'
import { pushToDataLayer } from 'gtm'
import { handleViewport } from 'react-in-viewport'
import {
  PopularCourses,
  TopLocationCard,
  TopSiteCategoryCard,
  TopSiteCard,
  AdventureSliderCard,
} from './sliders/cards'

import PropTypes from 'prop-types'

const SliderCard = ({ item, name, activityType, regionIdentifier }) => {
  return useMemo(() => {
    switch (name) {
      case 'popular-courses':
        return (
          <PopularCourses data={item} regionIdentifier={regionIdentifier} />
        )
      case 'top-locations':
        return (
          <TopLocationCard data={item} regionIdentifier={regionIdentifier} />
        )
      case 'top-sites-categories':
        return (
          <TopSiteCategoryCard
            data={item}
            regionIdentifier={regionIdentifier}
          />
        )
      case 'top-sites':
        return <TopSiteCard data={item} regionIdentifier={regionIdentifier} />
      case 'book-dives-courses':
        return (
          <AdventureSliderCard
            key={item.activityId}
            item={item}
            regionIdentifier={regionIdentifier}
            tab={activityType}
          />
        )
      default:
        return null
    }
  }, [name, item])
}

const responsiveSettings = [
  { breakpoint: 1000, settings: { slidesToShow: 3, slidesToScroll: 3 } },
  {
    breakpoint: 840,
    settings: { slidesToShow: 2, slidesToScroll: 1, arrows: false },
  },
  {
    breakpoint: 481,
    settings: { slidesToShow: 1, slidesToScroll: 1, arrows: false },
  },
]

const SliderDg = ({
  data,
  view,
  name,
  activityType,
  regionIdentifier,
  selectedCurrency,
  inViewport,
  enterCount,
  forwardedRef,
}) => {
  let sliderRef = useRef(null)

  const isImpressionRequired = get(data, '[0].impression')
  const settings = {
    slidesToShow: view,
    slidesToScroll: view,
    infinite: false,
    dots: true,
    lazyLoad: 'ondemand',
    responsive: responsiveSettings,
    beforeChange: beforeChangeHandler,
  }

  useEffect(() => {
    const numberOfItemsToShow = get(
      sliderRef,
      'current.innerSlider.props.slidesToShow',
    )
    if(isImpressionRequired && numberOfItemsToShow) {
      pushToDataLayer({
        event: 'impression',
        ecommerce: {
          currencyCode: selectedCurrency,
          impressions: data
            .slice(0, numberOfItemsToShow)
            .map((item) => item.impression),
        },
      })
      // scroll to first slide when user change tabs
      sliderRef.slickGoTo(0)
    }
  }, [sliderRef, data])

  if(!Array.isArray(data)) {
    return null
  }

  if(!inViewport && enterCount === 0) {
    return <div ref={forwardedRef} />
  }

  function beforeChangeHandler(current, next) {
    const difference = current + Math.abs(next - current)
    pushToDataLayer({
      event: 'impression',
      ecommerce: {
        currencyCode: selectedCurrency,
        impressions: data
          .slice(current, difference)
          .map((item) => item.impression),
      },
    })
  }

  return (
    <div className='slider-wrapper' ref={forwardedRef}>
      <Slider
        className='slick-pt-custom space-between same-height'
        {...settings}
        ref={(slider) => (sliderRef = slider)}
      >
        {data &&
          data.map((item) => (
            <SliderCard
              key={item.id || item.name || item.slug}
              name={name}
              item={item}
              regionIdentifier={regionIdentifier}
              activityType={activityType}
            />
          ))}
      </Slider>
    </div>
  )
}

const SliderDiveGuides = handleViewport(
  SliderDg,
  {},
  { disconnectOnLeave: true },
)

export default connect((state) => ({
  selectedCurrency: state.selectedCurrency,
}))(SliderDiveGuides)

SliderDg.propTypes = {
  data: PropTypes.array,
  view: PropTypes.number,
  name: PropTypes.string,
  activityType: PropTypes.string,
  regionIdentifier: PropTypes.string,
  selectedCurrency: PropTypes.string,
  inViewport: PropTypes.bool,
  enterCount: PropTypes.number,
  forwardedRef: PropTypes.object,
}
