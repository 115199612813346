const POUNDS_TO_KG_MODIFICATOR = 2.20462
const INCH_TO_CM_MODIFICATOR = 2.54
const FEET_TO_CM_MODIFICATOR = 30.48

// Please make sure that kinds corresponds with info.account

const KG_UNIT_KIND = 10
const POUNDS_UNIT_KIND = 20
const CM_UNIT_KIND = 10
const FEET_UNIT_KIND = 20

function kgToPounds(amount) {
  return Math.round((amount *= POUNDS_TO_KG_MODIFICATOR) * 10) / 10
}

function poundsToKg(amount) {
  return Math.round((amount /= POUNDS_TO_KG_MODIFICATOR) * 10) / 10
}

function cmToInches(amount) {
  return Math.round(amount / INCH_TO_CM_MODIFICATOR)
}

function inchesToCm(amount) {
  return Math.round(amount * INCH_TO_CM_MODIFICATOR)
}

function cmToFeets(amount) {
  return Math.round(amount / FEET_TO_CM_MODIFICATOR)
}

function feetsToCm(amount) {
  return Math.round(amount * FEET_TO_CM_MODIFICATOR)
}

export function convertToF(celsius) {
  return Math.round(((celsius * 9) / 5 + 32) * 100) / 100
}

/** @function convertHeightController
 * Handle converting height
 * @param {string} data - string with weight amount.
 * @param {number} to - unit kind, function works with KG or POUNDS.
 * @returns {number} amount - converted amount
 */

export function convertWeightController(data, to = KG_UNIT_KIND) {
  if(!data) {
    return null
  }

  const amount = parseFloat(data)

  if(to === KG_UNIT_KIND) {
    return poundsToKg(amount)
  } else if(to === POUNDS_UNIT_KIND) {
    return kgToPounds(amount)
  }

  return amount
}

/** @function convertHeightController
 * Handle converting height
 * @param {string} data - string with height amount.
 * @param {number} to - unit kind, function works with CM or FT.
 * @returns {string || number} amount - converted amount
 */

export function convertHeightController(data, to = CM_UNIT_KIND) {
  if(!data) {
    return null
  }

  let amount = data

  if(to === CM_UNIT_KIND && typeof amount === 'string') {
    // feets data is a string like  "5'9"
    const [feetsAmount, inchesAmount] = amount.split("'")

    return feetsToCm(feetsAmount) + inchesToCm(inchesAmount)
  } else if(to === FEET_UNIT_KIND) {
    const intFeets = Math.trunc(cmToFeets(amount))
    const inches = Math.round(cmToInches(amount - intFeets))

    return `${intFeets}'${inches}`
  }

  return amount
}
