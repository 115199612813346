import get from 'lodash/get'
import '../styles/guide-map-popup.scss'
import { FormattedMessage } from 'react-intl'
import { DiveData, WhereToDiveData } from '../map-popups'
import { dummyImagePath } from '../../../config'

export default function GuideMapPopup({
  info,
  isWhereToDive,
  isTravelMap,
  isDiveShop,
  isLeafletPopup,
  handleCloseClick,
  viewGuideLink,
  hideViewDetails,
  regionIdentifier,
  showWidget,
}) {
  const image = get(
    info,
    'image.400x400',
    get(info, 'images[0].500x500', dummyImagePath)
  )
  const showWhereToDive = isWhereToDive && !isTravelMap
  const showImage = isWhereToDive || isTravelMap
  return (
    <div
      className={`guide-map-popup ${
        isLeafletPopup ? 'leaflet-popup-container' : ''
      }`}
    >
      <i className='font-icons close-icon' onClick={handleCloseClick} />
      {showImage && (
        <div className='guide-map-popup__image-container'>
          <img src={image} className='guide-map-popup__image' />
        </div>
      )}
      <div className='guide-map-popup__content'>
        <span
          className='guide-map-popup__title'
          dangerouslySetInnerHTML={{ __html: info.title }}
        />
        {showWhereToDive ? (
          <WhereToDiveData info={info} />
        ) : (
          <DiveData
            info={info}
            isDiveShop={isDiveShop || info.isDiveShop}
            isTravelMap={isTravelMap}
            hideContactShop={info.isDiveShop}
            hideViewDetails={hideViewDetails}
            regionIdentifier={regionIdentifier}
            showWidget={showWidget}
          />
        )}
        {viewGuideLink && (
          <a
            className='btn-guides-blue'
            href={viewGuideLink}
            data-navigation-elements='data-navigation-elements'
            data-region-identifier={regionIdentifier}
          >
            <FormattedMessage
              id='view_dive_guide'
              defaultMessage='View dive guide'
            />
          </a>
        )}
      </div>
    </div>
  )
}
