import { defineMessages } from 'react-intl'

const tabsMessages = defineMessages({
  sites: {
    id: 'dive_sites',
    defaultMessage: 'Dive Sites',
  },
  shops: {
    id: 'dive_shops',
    defaultMessage: 'Dive Shops',
  },
  courses: {
    id: 'courses',
    defaultMessage: 'Courses',
  },
  diving: {
    id: 'diving',
    defaultMessage: 'Diving',
  },
  resorts: {
    id: 'dive_resorts',
    defaultMessage: 'Dive resorts',
  },
  liveaboards: {
    id: 'liveaboards',
    defaultMessage: 'Liveaboards',
  },
  conservation: {
    id: 'conservation',
    defaultMessage: 'Conservation',
  },
  snorkeling: {
    id: 'snorkeling',
    defaultMessage: 'Snorkeling',
  },
})

export const buttonsMessages = defineMessages({
  sites: {
    id: 'guides.show_all_dive_sites',
    defaultMessage: 'Show all dive sites',
  },
  shops: {
    id: 'guides.show_all_dive_shops',
    defaultMessage: 'View all dive shops',
  },
  courses: {
    id: 'guides.show_all_courses',
    defaultMessage: 'Show all bookable courses',
  },
  diving: {
    id: 'guides.show_all_dive_trips',
    defaultMessage: 'Show all bookable dive trips',
  },
  snorkeling: {
    id: 'guides.show_all_snorkeling',
    defaultMessage: 'Show all bookable snorkeling',
  },
  resorts: {
    id: 'guides.show_all_resorts',
    defaultMessage: 'Show all bookable resorts',
  },
  liveaboards: {
    id: 'guides.show_all_liveaboards',
    defaultMessage: 'Show all bookable liveaboards',
  },
  all: {
    id: 'view_all',
    defaultMessage: 'View all',
  },
  conservation: {
    id: 'guides.conservation',
    defaultMessage: 'Show all bookable conservations',
  },
})

export const tabsArray = [
  {
    icon: 'icon-dive-center',
    id: 'dive-centers',
    title: tabsMessages.shops,
    isTravel: true,
    route: 'diveShops',
    buttonTitle: buttonsMessages.shops,
  },
  {
    icon: 'dive-resort-icon',
    id: 'resorts',
    title: tabsMessages.resorts,
    isTravel: true,
    route: 'searchDr',
    buttonTitle: buttonsMessages.resorts,
  },
  {
    icon: 'liveaboard-icon',
    id: 'liveaboards',
    title: tabsMessages.liveaboards,
    isTravel: true,
    route: 'searchLa',
    buttonTitle: buttonsMessages.liveaboards,
  },
  {
    icon: 'icon-dive-sites',
    id: 'dive-sites',
    title: tabsMessages.sites,
    isTravel: false,
    route: 'diveSites',
    buttonTitle: buttonsMessages.sites,
  },
  {
    icon: 'icon-course',
    id: 'courses',
    title: tabsMessages.courses,
    isTravel: true,
    route: 'searchCourses',
    buttonTitle: buttonsMessages.courses,
  },
  {
    icon: 'icon-diver',
    id: 'dive-trips',
    title: tabsMessages.diving,
    isTravel: true,
    route: 'searchDiving',
    buttonTitle: buttonsMessages.diving,
  },
  {
    icon: 'icon-conservation-coral',
    id: 'conservation',
    title: tabsMessages.conservation,
    isTravel: true,
    route: 'searchConservation',
    buttonTitle: buttonsMessages.conservation,
  },
]

export const adventureTabsArray = [
  {
    id: 'dive-trips',
    title: tabsMessages.diving,
    route: 'searchDiving',
    buttonTitle: buttonsMessages.all,
  },
  // {
  //   id: 'courses',
  //   title: tabsMessages.courses,
  //   route: 'search_courses',
  //   buttonTitle: buttonsMessages.all,
  // },
  {
    id: 'snorkeling',
    title: tabsMessages.snorkeling,
    route: 'searchSnorkeling',
    buttonTitle: buttonsMessages.all,
  },
]

export const widgetTabsArray = [
  {
    id: 'dive-trips',
    title: tabsMessages.diving,
    route: 'searchDiving',
    buttonTitle: buttonsMessages.all,
  },
  {
    id: 'snorkeling',
    title: tabsMessages.snorkeling,
    route: 'searchSnorkeling',
    buttonTitle: buttonsMessages.all,
  },
]
