import { useState } from 'react'
import SearchLocationSubItemsList from './SearchLocationSubItemsList'
import { connect } from 'react-redux'
import { showLoader } from '../../../actions/main-actions'
import { trackClick } from '../../dive-guides/gtm-events'

const SearchLocation = ({
  location,
  isFirstRow,
  showLoader,
  isDiveGuides,
  isConservation,
}) => {
  const {
    liveaboardSearchUrl,
    diveResortSearchUrl,
    diveCenterSearchUrl,
    diveSearchUrl,
    snorkellingSearchUrl,
    courseSearchUrl,
    diveShopUrl,
    diveSiteUrl,
    diveGuideUrl,
    conservationSearchUrl,
  } = location
  const adventuresUrl =
    diveSearchUrl || courseSearchUrl || snorkellingSearchUrl
  const [isActive, setActive] = useState(isFirstRow)
  const toggleActive = () => setActive(!isActive)
  const handleLocationClick = () => {
    document.activeElement.blur()
    showLoader(true)
    const link =
      diveGuideUrl ||
      liveaboardSearchUrl ||
      diveSiteUrl ||
      diveShopUrl ||
      diveResortSearchUrl ||
      diveCenterSearchUrl ||
      adventuresUrl ||
      conservationSearchUrl

    if(isDiveGuides || isConservation) {
      trackClick({ regionIdentifier: 'search-auto-complete', link })
    }

    window.location.href = link
  }
  return (
    <div className='travel-search-container__list'>
      <div className='travel-search-container__row search-row'>
        <div
          className='travel-search-container__location'
          onClick={handleLocationClick}
        >
          <i className='font-icons location-icon' />
          <div className='travel-search-container__location__wrapper'>
            <span className='travel-search-container__location__title'>
              {location.title}
            </span>
            <span className='travel-search-container__location__formatted-address'>
              {location.formattedAddress}
            </span>
          </div>
        </div>
        <i
          className={`${
            isActive ? 'top-arrow-icon' : 'bottom-arrow-icon'
          } font-icons  arrow-icon`}
          onClick={toggleActive}
        />
      </div>
      {isActive && (
        <SearchLocationSubItemsList
          liveaboardSearchUrl={liveaboardSearchUrl}
          diveResortSearchUrl={diveResortSearchUrl}
          diveSearchUrl={diveSearchUrl}
          diveCenterSearchUrl={diveCenterSearchUrl}
          adventuresUrl={adventuresUrl}
          diveShopUrl={diveShopUrl}
          diveSiteUrl={diveSiteUrl}
          conservationSearchUrl={conservationSearchUrl}
          isDiveGuides={isDiveGuides}
          isConservation={isConservation}
        />
      )}
    </div>
  )
}

const mapDispatchToProps = { showLoader }

export default connect(null, mapDispatchToProps)(SearchLocation)
