import { connect } from 'react-redux'
import { showLoader } from '../../../actions/main-actions'
import { trackClick } from '../../dive-guides/gtm-events'

const SearchLocationSubItem = ({
  icon,
  title,
  url,
  showLoader,
  isDiveGuides,
  isConservation,
}) => {
  const handleClick = () => {
    document.activeElement.blur()
    showLoader(true)
    if(isDiveGuides || isConservation) {
      trackClick({ regionIdentifier: 'search-auto-complete', link: url })
    }
    window.location.href = url
  }
  return (
    <div
      className='travel-search-container__sub-row search-row'
      onClick={handleClick}
    >
      <div className='travel-search-container__sub-row__content'>
        <i className={`font-icons ${icon}`} />
        {title}
      </div>
    </div>
  )
}

const mapDispatchToProps = { showLoader }

export default connect(null, mapDispatchToProps)(SearchLocationSubItem)
