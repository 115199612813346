import { FormattedMessage } from 'react-intl'

const getMessage = ({ isDiveGuides, isConservation, worldUrl }) => {
  if(isDiveGuides) {
    return (
      <FormattedMessage
        id='broadening_area'
        defaultMessage='Try broadening your search area'
      />
    )
  } else if(isConservation) {
    return (
      <FormattedMessage
        id='broadening_conservation_with_url'
        values={{
          a: (chunks) => <a href={worldUrl}>{chunks}</a>,
        }}
        defaultMessage='Try broadening your search area or <a>see all conservation activites</a> all over the world'
      />
    )
  }

  return (
    <FormattedMessage
      id='sorry_not_recognize'
      defaultMessage='Sorry, we don’t recognize that name'
    />
  )
}

export const SearchNoResults = ({ isDiveGuides, isConservation, worldUrl }) => {
  return (
    <div className='travel-search-container__location travel-search-container__location--no-results'>
      <i className='font-icons find-icon' />
      {getMessage({ isDiveGuides, isConservation, worldUrl })}
    </div>
  )
}
