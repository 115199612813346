import API from '../../../api'
import './travel-search.scss'
import {
  useState,
  useEffect,
  useRef,
  useMemo,
  forwardRef,
  useImperativeHandle,
} from 'react'
import { useIntl } from 'react-intl'
import { makeCounter, getOffset } from '../../../utils'
import messages from '../../../messages'
import SearchLocation from './SearchLocation'
import { SearchNoResults } from './SearchNoResults'
import SearchShopItem from './SearchShopItem'

const toggleFullScreen = (popup, onDestinationPopupToggle) => {
  if(!popup) {
    return false
  }
  if(window.innerWidth < 768) {
    window.scrollTo(0, 0)
    document.querySelector('body').classList.add('open-modal')
    popup.style.top = '70px'
    popup.style.bottom = '0'
    onDestinationPopupToggle(true)
  }
}

const showPopup = (popup, inputRef, onDestinationPopupToggle) => {
  if(!popup) {
    return false
  }
  const inputOffset = getOffset(inputRef)
  popup.style.opacity = 1

  popup.style.left = `${(window.innerWidth - popup.offsetWidth) / 2}px`
  popup.style.top = `${inputOffset.top + 50}px`
  popup.style.bottom = ''

  toggleFullScreen(popup, onDestinationPopupToggle)
}

const hidePopup = (popup, onDestinationPopupToggle) => {
  if(!popup) {
    return false
  }
  popup.style.opacity = 0
  if(typeof onDestinationPopupToggle === 'function') {
    document.querySelector('body').classList.remove('open-modal')
    onDestinationPopupToggle(false)
  }
  setTimeout(() => {
    popup.style.left = '-9999px'
  }, 300)
}

const TravelSearch = forwardRef((props, ref) => {
  const {
    icon,
    popupId,
    activateHeaderSearch,
    setRedirectUrl,
    onDestinationPopupToggle,
    isDiveGuides,
    isConservation,
    worldUrl,
  } = props

  const intl = useIntl()

  const [searchString, setSearchString] = useState('')
  const [locations, setLocations] = useState([])
  const [shops, setShops] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [isVisible, setVisible] = useState(false)
  const [searchNoResults, setSearchNoResults] = useState(false)
  const [isInitialActivation, setIsInitialActivation] = useState(true)
  const container = useRef()
  const inputRef = useRef()
  const controller = useRef()
  const signal = useRef()
  const counter = makeCounter()
  const API_URL = useMemo(() => {
    if(isConservation) {
      return 'search/autosuggest/sto/'
    } else if(isDiveGuides) {
      return 'dive-guide/autosuggest/'
    }

    return 'search/autosuggest/homepage/'
  }, [])

  useImperativeHandle(ref, () => ({
    showResults() {
      if(!searchString) {
        setSearchNoResults(true)
        setIsInitialActivation(false)
        onFocus()
      }
    },
  }))

  const getSearchPlaceholder = () => {
    let placeholder = messages.homepageSearchPlaceholder
    if(isConservation) {
      placeholder = messages.homepageSearchPlaceholderConservation
    } else if(isDiveGuides) {
      placeholder = messages.divingInPlaceholder
    }
    return intl.formatMessage(placeholder)
  }

  const popup = useMemo(() => {
    return document.getElementById(popupId)
  }, [])
  const onMobileCloseBtnClick = () => {
    setVisible(false)
    hidePopup(popup, onDestinationPopupToggle)
  }

  const handleChange = ({ target }) => {
    const text = target.value
    setSearchNoResults(false)
    setSearchString(text)
    if(text.length > 2) {
      if(controller.current !== undefined) {
        controller.current.abort()
      }

      if('AbortController' in window) {
        controller.current = new window.AbortController()
        signal.current = controller.current.signal
      }

      API(API_URL, signal.current)
        .get({
          q: text,
        })
        .then((data) => {
          const locations =
            isDiveGuides || isConservation
              ? data
              : [...data.gplaces, ...data.places]

          setLocations(locations)

          if(data.shops && data.shops.length) {
            const items = []

            data.shops.forEach((shop) => {
              items.push(shop)
              if(shop.kind === 20 && shop.diveCenterUrl) {
                items.push({
                  ...shop,
                  travelUrl: shop.diveCenterUrl,
                  title: shop.diveCenterTitle,
                  kind: 0,
                })
              }
            })
            setShops(items)
          }
          setIsInitialActivation(false)

          let url = ''

          if(locations.length) {
            url = isDiveGuides
              ? locations[0].diveSearchUrl
              : locations[0].liveaboardSearchUrl
          }
          setRedirectUrl(url)
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      setLocations([])
      setShops([])
    }
  }
  const onFocus = () => {
    setVisible(true)
    if(activateHeaderSearch) {
      activateHeaderSearch(true)
    }
  }

  const onBlur = () => {
    if(window.innerWidth >= 768) {
      setSearchNoResults(false)
      setVisible(false)
      hidePopup(popup, onDestinationPopupToggle)
    }
  }

  const triggerPopup = () => {
    if(!popup) {
      return false
    }
    if(searchString.length < 3) {
      showPopup(popup, inputRef.current, onDestinationPopupToggle)
    } else {
      hidePopup(popup)
    }
  }

  const onArrowPress = (type) => {
    const currentContainer = container.current
    const rows = currentContainer.querySelectorAll('.search-row')

    if(type === 'down') {
      if(counter.value() < rows.length) {
        counter.increment()
      } else {
        counter.setValue(1)
      }
    } else {
      if(counter.value() > 1) {
        counter.decrement()
      } else {
        counter.setValue(rows.length)
      }
    }
    rows.forEach((row, index) => {
      row.classList.remove('active')
      if(index + 1 === counter.value()) {
        row.classList.add('active')
        row.focus()
        currentContainer
          .querySelector('.travel-search-container__results')
          .scrollTo(0, row.offsetTop)
      }
    })
  }

  const onKeyPress = ({ key }) => {
    if(key === 'ArrowDown') {
      onArrowPress('down')
    } else if(key === 'ArrowUp') {
      onArrowPress('up')
    } else if(key === 'Enter') {
      const currentContainer = container.current
      const activeElement =
        currentContainer.querySelector('.search-row.active') ||
        currentContainer.querySelector('.search-row')
      if(activeElement) {
        activeElement.click()
        activeElement
          .querySelector('.travel-search-container__location')
          .click()
      }
    }
  }

  useEffect(() => {
    if(!isVisible) {
      hidePopup(popup)
    } else {
      triggerPopup()
      toggleFullScreen(popup, onDestinationPopupToggle)
    }
  }, [searchString, isVisible])

  useEffect(() => {
    container.current.addEventListener('keydown', onKeyPress)
  }, [])

  return (
    <div
      className='travel-search-container'
      onFocus={onFocus}
      onBlur={onBlur}
      tabIndex='1'
      ref={container}
    >
      <div className='relative'>
        <i className={`font-icons field-icon ${icon || 'location-icon'}`} />
        <input
          data-testid='search-input'
          value={searchString}
          onChange={handleChange}
          ref={inputRef}
          className='travel-search-input'
          placeholder={getSearchPlaceholder()}
        />
        <span
          className='close-destination-popup close-icon font-icons'
          onClick={onMobileCloseBtnClick}
        />
        {isLoading && (
          <span className='loader-icon'>
            <i className='font-icons icon-return rotating' />
          </span>
        )}
      </div>
      {!locations.length && !shops.length && !isInitialActivation && (
        <div
          className={`travel-search-container__results ${
            (!isVisible || searchString.length < 3) && !searchNoResults
              ? 'not-visible'
              : ''
          }`}
        >
          <SearchNoResults
            isDiveGuides={isDiveGuides}
            isConservation={isConservation}
            worldUrl={worldUrl}
          />
        </div>
      )}
      {!!(locations.length || shops.length) && (
        <div className='travel-search-container__results' hidden={!isVisible}>
          {locations.map((location, index) => (
            <SearchLocation
              key={`${location.title}${location.formattedAddress}${index}`}
              location={location}
              isFirstRow={index === 0}
              suggestionIndex={index}
              isDiveGuides={isDiveGuides}
              isConservation={isConservation}
            />
          ))}
          {shops.map((shop) => (
            <SearchShopItem shop={shop} key={shop.pk + shop.title} />
          ))}
        </div>
      )}
    </div>
  )
})

export default TravelSearch
