import { connect } from 'react-redux'
import { showLoader } from '../../../actions/main-actions'
import { trackClick } from '../../dive-guides/gtm-events'
import { getVerboseKind } from '../../../utils'

const getIcon = (kind) => {
  switch (kind) {
    case 0:
      return 'icon-dive-center'
    case 10:
      return 'liveaboard-icon'
    case 20:
      return 'dive-resort-icon'
  }
}

const SearchShopItem = ({ shop, showLoader, isDiveGuides, isConservation }) => {
  const handleLocationClick = () => {
    document.activeElement.blur()
    showLoader(true)

    if(isDiveGuides || isConservation) {
      trackClick({
        regionIdentifier: 'search-auto-complete',
        link: shop.travelUrl,
      })
    }

    window.location.href = shop.travelUrl
  }
  return (
    <div className='travel-search-container__list'>
      <div className='travel-search-container__row search-row'>
        <div
          className='travel-search-container__location'
          onClick={handleLocationClick}
        >
          <i className={`font-icons shop-icon ${getIcon(shop.kind)}`} />
          <div className='travel-search-container__location__wrapper'>
            <span className='travel-search-container__location__title'>
              {shop.title}
            </span>
            <span className='travel-search-container__location__formatted-address'>
              {getVerboseKind(shop.kind)}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapDispatchToProps = { showLoader }

export default connect(null, mapDispatchToProps)(SearchShopItem)
