import { useEffect, useMemo, Fragment } from 'react'
import PropTypes from 'prop-types'
import {
  TileLayer,
  ZoomControl,
  LayersControl,
  useMap,
  useMapEvents,
} from 'react-leaflet'
import debounce from 'lodash/debounce'
import { getLanguage } from '../../../utils'
import { getMapTileUrl, tiandituTilesUrl, isMainLand } from '../../../config'
import '../styles/map.scss'
import Markers from './Markers'

const language = getLanguage()

export default function Map({
  pins = [],
  onPinClick = () => {},
  selectedPin,
  selectedPinItem,
  incapsulatedMap,
  locationBounds,
  showPopupOnPinClick,
  onMapMove,
  preventCentering,
  enableHack,
  pinType = 'location',
  isDiveCenter,
  countryCode,
  onMapClick,
  bounds,
}) {
  const map = useMap()
  const activeMarker = incapsulatedMap ? selectedPinItem : selectedPin
  const onClick = (pin) => onPinClick({ ...pin, preventCentering: false })
  const onMoveEnd = debounce((e) => {
    if(typeof onMapMove === 'function') {
      onMapMove(e.target.getBounds())
    }
  }, 400)

  useMapEvents({
    moveend: onMoveEnd,
    click: onMapClick,
  })

  useEffect(() => {
    const centerL = map.getCenter()
    if(locationBounds?.isPoint) {
      // if location has no viewport it has only point bounds
      map.fitBounds(bounds, { maxZoom: 13 })
    }

    if(isDiveCenter && pins.length <= 1) {
      map.setView([centerL.lat + 0.0002, centerL.lng], 18)
    }
  }, [])

  useEffect(() => {
    if(activeMarker && !preventCentering && !activeMarker.preventCentering) {
      map.setView([activeMarker.latitude, activeMarker.longitude])
    }
  }, [activeMarker])

  // Hack to zoom out on mouse leave from the list
  useEffect(() => {
    if(preventCentering && (pins.length || locationBounds) && enableHack) {
      if(map) {
        const center = map.getCenter()
        // The second hack - I need to change a little bit center coordinates, so on the next hovering of the same element, it will change center.
        map.setView([center.lat + Math.DEG_PER_RAD / 100, center.lng])
        setTimeout(() => {
          map.fitBounds(bounds)
        }, 100)
      }
    }
  }, [preventCentering])

  const tileUrl = useMemo(
    () =>
      getMapTileUrl({
        isSateliteView: false,
        countryCode,
        language,
        isLight: true,
      }),
    [language]
  )

  return (
    <Fragment>
      <LayersControl position='topright'>
        <LayersControl.BaseLayer name='PADI map' checked>
          {isMainLand(countryCode) && <TileLayer url={tiandituTilesUrl} />}
          <TileLayer url={tileUrl} />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer name='Satellite'>
          <TileLayer url='//server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}' />
        </LayersControl.BaseLayer>
      </LayersControl>
      <ZoomControl position='topright' />
      <Markers
        pins={pins}
        pinType={pinType}
        onClick={onClick}
        selectedPin={activeMarker}
        showPopupOnPinClick={showPopupOnPinClick}
      />
    </Fragment>
  )
}

Map.propTypes = {
  pins: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      latitude: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired,
      longitude: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired,
    })
  ),
  onMapClick: PropTypes.func,
  onPinClick: PropTypes.func,
  onMapMove: PropTypes.func,
  selectedPin: PropTypes.object,
  showPopupOnPinClick: PropTypes.bool,
  preventCentering: PropTypes.bool,
  enableHack: PropTypes.bool,
  pinType: PropTypes.string,
}
