exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".buttonsGroup--1zq0Q{display:flex;justify-content:center;align-items:center;overflow-x:scroll;scroll-behavior:smooth;scrollbar-width:none;-ms-overflow-style:none;margin-bottom:20px}.buttonsGroup--1zq0Q::-webkit-scrollbar{display:none}.buttonIcon--3CxkV{font-size:25px;display:flex;align-items:center;justify-content:center;width:40px;height:40px;position:relative}.active--2g81T{border-bottom:3px solid #0070d3;color:#0070d3}.button--R4PJT{display:flex;flex-direction:column;align-items:center;justify-content:space-between;min-width:100px;font-weight:400;cursor:pointer;border:none;border-bottom:3px solid transparent;background:transparent;text-transform:capitalize;color:#657381;padding:12px 0;margin:0 20px}@media (max-width:768px){.button--R4PJT{margin:0 5px}}.button--R4PJT:first-child{margin-left:0}.button--R4PJT:last-child{margin-right:0}.button--R4PJT.active--2g81T,.button--R4PJT:hover{border-bottom:3px solid #0070d3;color:#0070d3}.button--R4PJT .dive-resort-icon--2gxnZ{font-size:32px}.button--R4PJT .icon-dive-sites--1srrs{font-size:38px}.button--R4PJT .liveaboard-icon--3ieAG{font-size:42px}.button--R4PJT .icon-diver--20Fmm{font-size:20px}", ""]);

// Exports
exports.locals = {
	"buttonsGroup": "buttonsGroup--1zq0Q",
	"buttonIcon": "buttonIcon--3CxkV",
	"active": "active--2g81T",
	"button": "button--R4PJT",
	"dive-resort-icon": "dive-resort-icon--2gxnZ",
	"icon-dive-sites": "icon-dive-sites--1srrs",
	"liveaboard-icon": "liveaboard-icon--3ieAG",
	"icon-diver": "icon-diver--20Fmm"
};