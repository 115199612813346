import { Fragment } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { handleEventDefault } from '../../../dom-handlers'
import { openSalesForcePopup, trackClick } from '../gtm-events'
import { isBookableShop } from '../utils'
import { target } from '../../../utils'
import DiveCenterLevel from './../DiveCenterLevel'
import get from 'lodash/get'

// Add PADI - PADI Travel Click event when Book online will be implemented

function DiveData({
  isDiveShop,
  isTravelMap,
  info,
  hideViewDetails,
  regionIdentifier,
  showWidget,
  hideContactShop,
}) {
  const intl = useIntl()
  const travelUrl = info.travel_url || info.travelUrl
  const dsType = Array.isArray(info.dive_site_types)
    ? info.dive_site_types.join(', ')
    : info.types
  const bookOnlineUrl =
    !isDiveShop && travelUrl ? travelUrl + '#adventures' : ''
  const widgetOptions = showWidget
    ? {
      'data-divesite-widget': true,
      'data-site-id': info.id,
      'data-shop-id': get(window, 'shop.id'),
    }
    : {}

  const diveSiteId = window.locationData && window.locationData.diveSiteId

  const bookOnlineShop = isBookableShop({ ...info, diveSiteId })

  const handleContactShopClick = (e) => {
    handleEventDefault(e)
    openSalesForcePopup(info, intl, true)
  }

  const handleDatailsClick = () => {
    if(isDiveShop) {
      trackClick({
        eventCategory: 'Dive Shop',
        eventAction: 'PADI - PADI Travel Click (Map)',
        customLabel: info.id,
      })
    }
  }

  return (
    <Fragment>
      {isDiveShop && (
        <span className='guide-map-popup__description'>
          <DiveCenterLevel info={info} />
        </span>
      )}
      {dsType && <span className='guide-map-popup__description'>{dsType}</span>}
      {!isDiveShop && (
        <div className='guide-map-popup__description'>
          <i className='font-icons icon-thumbs-up-new' />
          <span>
            {info.recommendationsPercentage ? (
              `${info.recommendationsPercentage}%`
            ) : (
              <FormattedMessage id='n_a' defaultMessage='N/A' />
            )}
            &nbsp;
          </span>
          <span>({info.numberReviews || 0})</span>
        </div>
      )}
      {info.coordinates && (
        <span className='guide-map-popup__description'>{info.coordinates}</span>
      )}
      {isDiveShop && !hideContactShop && (
        <a
          href='#'
          className='cs-card__sub-link'
          onClick={handleContactShopClick}
        >
          <i className='font-icons chat-icon' />
          <FormattedMessage id='contact_shop' defaultMessage='Contact Shop' />
        </a>
      )}
      {bookOnlineUrl && !isTravelMap && (
        <a
          className='cs-card__sub-link'
          target={target}
          href={bookOnlineUrl}
          data-navigation-elements='data-navigation-elements'
          data-region-identifier={regionIdentifier}
        >
          <i className='font-icons icon-calendar-new' />
          <FormattedMessage id='book_online' defaultMessage='Book online' />
        </a>
      )}
      {bookOnlineShop.travelShop && (
        <a
          {...bookOnlineShop.data}
          className='cs-card__sub-link'
          target={target}
          data-navigation-elements='data-navigation-elements'
          data-region-identifier={regionIdentifier}
        >
          <i className='font-icons icon-calendar-new' />
          <FormattedMessage id='book_online' defaultMessage='Book online' />
        </a>
      )}
      {!hideViewDetails && travelUrl && (
        <a
          className='btn-guides-blue'
          href={travelUrl}
          target={target}
          onClick={handleDatailsClick}
          data-navigation-elements='data-navigation-elements'
          data-region-identifier={regionIdentifier}
          {...widgetOptions}
        >
          <FormattedMessage id='view_details' defaultMessage='View Details' />
        </a>
      )}
    </Fragment>
  )
}

export default DiveData
