import { FormattedMessage } from 'react-intl'
import PriceConverter from '../../../helpers/PriceConverter'
import get from 'lodash/get'
import { dummyImagePath } from 'config'
import { pushToDataLayer } from 'gtm'
import { target } from 'utils'

export const ResortSliderCard = ({ item }) => {
  const picture = get(
    item,
    'background.GridSearchResultDesktop',
    dummyImagePath
  )
  const handleClick = () => {
    if(item.impression) {
      pushToDataLayer({
        event: 'productClick',
        ecommerce: {
          click: {
            actionField: { list: window.currentPageTypeAdventures },
            products: [item.impression],
          },
        },
      })
    }
  }
  return (
    <div key={item.shopId} className='operator-item-wrapper'>
      <a
        className='operator-item-card element operator-item-card-container'
        href={item.url}
        target={target}
        onClick={handleClick}
        data-navigation-elements='data-navigation-elements'
        data-region-identifier='book-diving'
      >
        <div className='img-wrapper ratio-16-9'>
          <span
            style={{ backgroundImage: `url(${picture})` }}
            className='img'
          />
        </div>

        <div className='operator-item-card__content'>
          <strong className='operator-item-card__title'>{item.title}</strong>
          <ul className='features'>
            <li>
              <i className='font-icons location-icon' />
              {item.locationTitle}
            </li>
            <li>
              <i className='font-icons dive-center-icon' />
              <FormattedMessage
                values={{
                  dives: item.numberOfDives / 2,
                }}
                id='including_dives'
                defaultMessage='Including { dives } dives'
              />
            </li>
          </ul>
          <div className='price-wrap'>
            <span className='price-type'>
              <FormattedMessage id='from' defaultMessage='From' />
            </span>

            <span className='price'>
              <PriceConverter amount={item.priceSum} currency={item.currency} />
            </span>

            <span className='price-type'>
              <FormattedMessage
                values={{
                  nights: item.nights,
                }}
                id='per_diver_for_nights'
                defaultMessage='per diver for { nights } nights'
              />
            </span>
          </div>
        </div>
      </a>
    </div>
  )
}
