import get from 'lodash/get'
import { useEffect, useState, useMemo } from 'react'
import API from 'api'
import { ActivityInclusionsSearchPage } from '../../../adventures/ActivityInclusions'
import AvailableDates from '../../../adventures/AvailableDate'
import PriceConverter from '../../../helpers/PriceConverter'
import InstantConfirmation from '../../../adventures/InstantConfirmation'
import { dummyImagePath } from 'config'
import { pushToDataLayer } from 'gtm'
import { target } from 'utils'
import { AdventureOriginalTitle } from '../../../adventures'

export const AdventureSliderCard = ({ item, tab }) => {
  const [image, setImage] = useState('')
  const isOnDiveSitePage =
    window.currentPageTypeAdventures === 'Travel Adventures - Dive Sites' // defined in template 'dive_site.html'

  const url = useMemo(() => {
    let updatedUrl = item.activityPage

    if(window.isWidget || isOnDiveSitePage) {
      updatedUrl += '&widget=true'
    }
    if(isOnDiveSitePage) {
      const diveSiteId = window.locationData && window.locationData.diveSiteId
      updatedUrl += `&dive_site_id=${diveSiteId}`
    }
    return updatedUrl
  }, [item.activityPage])

  const price = get(item, 'price.price', 0)
  const comparedPrice = get(item, 'compareAtPrice.price')
  const currency = get(item, 'price.currency', '')
  const handleClick = () => {
    if(item.impression) {
      pushToDataLayer({
        event: 'productClick',
        ecommerce: {
          click: {
            actionField: { list: window.currentPageTypeAdventures },
            products: [item.impression],
          },
        },
      })
    }
  }

  const widgetOptions = isOnDiveSitePage
    ? {
      'data-activity-widget': true,
      'data-activity-url': url,
    }
    : {}

  useEffect(() => {
    let controller
    let signal

    if('AbortController' in window) {
      controller = new window.AbortController()
      signal = controller.signal
    }

    API(`search/activity/${item.activityId}/images/`, signal)
      .get()
      .then((res) => {
        const url = get(res.results, '[0].picture.medium', dummyImagePath)
        setImage(url)
      })
    return () => controller && controller.abort()
  }, [])

  return (
    <div className='operator-item-wrapper'>
      <a
        className='operator-item-card bordered-element operator-item-card-container'
        href={url}
        target={window.isWidget ? null : target}
        onClick={handleClick}
        data-navigation-elements='data-navigation-elements'
        data-region-identifier='book-diving'
        {...widgetOptions}
      >
        <div className='img-wrapper ratio-16-9'>
          <span style={{ backgroundImage: `url(${image})` }} className='img' />
        </div>
        <div className='operator-item-card__content'>
          <strong className='operator-item-card__title'>
            {item.title}
            <AdventureOriginalTitle
              originalTitle={item.originalTitle}
              title={item.title}
            />
          </strong>
          <p className='dive-center-title'>
            {item.diveCenter && item.diveCenter.title}
          </p>
          <p className='operator-item-card__inclusion'>
            <ActivityInclusionsSearchPage item={item} activityType={tab} />
          </p>
          <p className='operator-item-card__availability'>
            <AvailableDates date={item.date} />
          </p>
          <div className='price-wrap'>
            {comparedPrice > price && (
              <span className='old-pricing'>
                <PriceConverter
                  amount={comparedPrice}
                  currency={currency}
                  conversionPercent
                />
              </span>
            )}
            <span className='price'>
              <PriceConverter
                amount={price}
                currency={currency}
                conversionPercent
              />
              {item.instantConfirmation && <InstantConfirmation />}
            </span>
          </div>
        </div>
      </a>
    </div>
  )
}
