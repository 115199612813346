import { Fragment } from 'react'
import { useIntl } from 'react-intl'
import { tabsArray } from './book-diving/tabs'
import { connect } from 'react-redux'
import { setTabType } from '../../actions/dive-guide-actions'
import ShopTypesDivider from './ShopTypesDivider'

const ShopTypesToggle = ({ setTabType }) => {
  const intl = useIntl()
  const handleClick = (tabId) => {
    setTabType(tabId)
  }

  const buttons = tabsArray.map((tab) => (
    <a
      key={tab.id}
      data-tab={tab.id}
      onClick={() => handleClick(tab.id)}
      className='shop-type'
      href='#book-diving'
    >
      <i className={`font-icons ${tab.icon}`} />
      <span>{intl.formatMessage(tab.title)}</span>
    </a>
  ))

  const tabs = [
    ...buttons.slice(0, 4),
    <ShopTypesDivider dividerClass='divider' />,
    ...buttons.slice(4),
  ]

  return <Fragment>{tabs}</Fragment>
}

const mapStateToProps = (state) => ({
  selectedTab: state.selectedTab,
})

export default connect(mapStateToProps, { setTabType })(ShopTypesToggle)
