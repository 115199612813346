import { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import moment from 'moment'
import { APP_LANGUAGE } from '../../../localized-urls'
import { CalendarGroupItem } from './CalendarGroupItem'
import groupBy from 'lodash/groupBy'

const monthesShort = moment.localeData(APP_LANGUAGE).monthsShort()

const CalendarContainer = ({ data }) => {
  let result = []

  if(data) {
    const grouped = groupBy(data, 'group.title')
    Object.values(grouped).forEach((e) => result.push(e))
  }

  return (
    <Fragment>
      <div className='calendar-title'>
        <FormattedMessage id='calendar' defaultMessage='Calendar' />
      </div>
      <div className='calendar-list'>
        {monthesShort.map((month) => (
          <span key={month}>{month}</span>
        ))}
      </div>

      <div className='calendar-container'>
        {result.length ? (
          result.map((group) =>
            group[0]?.group?.id ? (
              <CalendarGroupItem key={group[0].group.id} group={group} />
            ) : null
          )
        ) : (
          <div className='no-results'>
            <FormattedMessage id='no_results' defaultMessage='No results' />
          </div>
        )}
      </div>

      <div className='table-info'>
        <span className='most'>
          <FormattedMessage
            id='most_likely_sightings'
            defaultMessage='Most likely sightings'
          />
        </span>
        <span className='possible'>
          <FormattedMessage
            id='possible_sightings'
            defaultMessage='Possible sightings'
          />
        </span>
      </div>
    </Fragment>
  )
}

export default CalendarContainer
