import { useMemo, useState } from 'react'
import get from 'lodash/get'
import { Marker, Popup } from 'react-leaflet'
import GuideMapPopup from './GuideMapPopup'
import {
  dslDiveSitePin,
  dslDiveSitePinSelected,
  dslFiveStarPin,
  dslFiveStarPinSelected,
  locationIcon,
  locationIconSelected,
} from '../../../config'

const markersType = {
  location: {
    normal: locationIcon,
    selected: locationIconSelected,
  },
  diveSite: {
    normal: dslDiveSitePin,
    selected: dslDiveSitePinSelected,
  },
  diveShop: {
    normal: dslFiveStarPin,
    selected: dslFiveStarPinSelected,
  },
}
export default function Markers({
  pins,
  pinType,
  selectedPin,
  onClick,
  showPopupOnPinClick,
}) {
  const [hoveredPin, setHoveredPin] = useState()
  const onMouseOver = ({ target }) => {
    setHoveredPin(target.options.id)
  }
  const onMouseOut = () => {
    setHoveredPin(null)
  }
  return useMemo(() => {
    const selectedPinId = get(selectedPin, 'id')
    return pins.map((pin) => {
      const onPinClick = () => onClick(pin)
      const position = [pin.latitude, pin.longitude]
      const pinTypeValue = pin.pinType || pinType
      if(!pin.latitude || !pin.longitude) {
        return null
      }
      return (
        <Marker
          key={pin.id}
          id={pin.id}
          position={position}
          icon={
            selectedPinId === pin.id || hoveredPin === pin.id
              ? markersType[pinTypeValue].selected
              : markersType[pinTypeValue].normal
          }
          zIndexOffset={selectedPinId === pin.id ? 1000 : 0}
          eventHandlers={{
            click: onPinClick,
            mouseover: onMouseOver,
            mouseout: onMouseOut,
          }}
        >
          {showPopupOnPinClick ? (
            <Popup className='request-popup'>
              <GuideMapPopup info={pin} isLeafletPopup />
            </Popup>
          ) : null}
        </Marker>
      )
    })
  }, [pins, selectedPin, hoveredPin])
}
