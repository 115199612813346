import styles from './Map.module.scss'
import { FormattedMessage } from 'react-intl'

const NoResultsMap = () => {
  return (
    <div className={styles.noResultsMap}>
      <FormattedMessage
        id='no_information_available_at_this_time'
        defaultMessage='No information available at this time'
      />
    </div>
  )
}

export default NoResultsMap
