import { useEffect, useState } from 'react'
import Highcharts from 'highcharts'
import { useIntl, defineMessages, FormattedMessage } from 'react-intl'
import { convertToF } from '../helpers/UnitConvertor'

import moment from 'moment'

import Charts from './Charts'

const messages = defineMessages({
  temperature: {
    id: 'temperature',
    defaultMessage: 'Temperature',
  },
  rainfall: {
    id: 'rainfall',
    defaultMessage: 'Rainfall',
  },
})

const getInitialState = (converter, intl) => {
  return {
    chart: {
      zoomType: 'xy',
    },
    title: {
      text: '',
    },
    subtitle: {
      text: '',
    },
    xAxis: [
      {
        categories: moment.monthsShort(),
        crosshair: true,
      },
    ],
    yAxis: [
      {
        // Primary yAxis
        labels: {
          format: `{value} ${converter ? `°F` : `°C`}`,
          style: {
            color: Highcharts.getOptions().colors[1],
          },
        },
        title: {
          text: '',
          style: {
            color: Highcharts.getOptions().colors[1],
          },
        },
      },
      {
        // Secondary yAxis
        title: {
          text: '',
          style: {
            color: Highcharts.getOptions().colors[0],
          },
        },
        labels: {
          format: `{value} ${converter ? `inch` : `mm`}`,
          style: {
            color: Highcharts.getOptions().colors[0],
          },
        },
        opposite: true,
      },
    ],
    tooltip: {
      shared: true,
    },
    series: [
      {
        name: intl.formatMessage(messages.temperature),
        type: 'column',
        color: '#66D9E8',
        data: [],
        tooltip: {
          valueSuffix: `{value} ${converter ? `°F` : `°C`}`,
        },
      },
      {
        name: intl.formatMessage(messages.rainfall),
        type: 'spline',
        color: '#F23D4E',
        yAxis: 1,
        data: [],
        tooltip: {
          valueSuffix: `{value} ${converter ? `inch` : `mm`}`,
        },
      },
    ],
  }
}

const mockOptions2 = {
  chart: {
    zoomType: 'xy',
  },
  title: {
    text: '',
  },
  subtitle: {
    text: '',
  },
  xAxis: [
    {
      categories: moment.monthsShort(),
      crosshair: true,
    },
  ],
  yAxis: [
    {
      // Primary yAxis
      labels: {
        format: '{value}°F',
        style: {
          color: Highcharts.getOptions().colors[1],
        },
      },
      title: {
        text: '',
        style: {
          color: Highcharts.getOptions().colors[1],
        },
      },
    },
    {
      // Secondary yAxis
      title: {
        text: '',
        style: {
          color: Highcharts.getOptions().colors[0],
        },
      },
      labels: {
        format: '{value} inch',
        style: {
          color: Highcharts.getOptions().colors[0],
        },
      },
      opposite: true,
    },
  ],
  tooltip: {
    shared: true,
  },
  series: [
    {
      name: '',
      type: 'column',
      color: '#0070D3',
      data: [
        68.1,
        67.1,
        66.1,
        62.5,
        63.1,
        61.1,
        60.1,
        59.1,
        60.1,
        60.1,
        59.1,
        63.1,
      ],
      tooltip: {
        valueSuffix: '°F',
      },
    },
  ],
}

const HighCharts = ({ type, highChartsCountry }) => {
  const intl = useIntl()
  const [converter, setConverter] = useState(true)
  const [data, setData] = useState(getInitialState(converter, intl))

  const addCoordinates = (state) => {
    Object.values(state.series).forEach((item) => {
      Object.values(highChartsCountry).forEach((prop) => {
        if(converter) {
          item.data.push(
            item.type === 'column'
              ? convertToF(prop.avg)
              : Math.round(prop.rain_mm * 2.54)
          )
        } else {
          item.data.push(item.type === 'column' ? prop.avg : prop.rain_mm)
        }
      })
    })
  }

  const handleClick = () => setConverter(!converter)

  useEffect(
    function() {
      const initialState = getInitialState(converter, intl)
      switch (type) {
        case 'dive-site':
          setData(mockOptions2)
          break
        case 'country':
          addCoordinates(initialState)
          setData(initialState)
          break
        default:
          setData(mockOptions2)
      }
    },
    [converter]
  )

  return (
    <div className='high-charts-wrap'>
      <Charts data={data} />
      {type === 'country' ? (
        <div className='high-charts-btn-group'>
          <button
            className={
              converter ? 'high-charts-button' : 'high-charts-button active'
            }
            onClick={handleClick}
          >
            <FormattedMessage id='metric' defaultMessage='Metric' />
          </button>
          <button
            className={
              converter ? 'high-charts-button active' : 'high-charts-button'
            }
            onClick={handleClick}
          >
            <FormattedMessage id='imperial' defaultMessage='Imperial' />
          </button>
        </div>
      ) : null}
    </div>
  )
}

export default HighCharts
