import { useMemo } from 'react'
import { latLngBounds } from 'leaflet'

export default function useMapInitialBounds(
  locationBounds,
  pins,
  isOperatorMap
) {
  const initBounds = useMemo(() => {
    const b = latLngBounds()
    if(!locationBounds) {
      pins.forEach((coord) => {
        if(coord.latitude && coord.longitude) {
          b.extend([coord.latitude, coord.longitude])
        }
        if(isOperatorMap) {
          const { point } = coord
          b.extend([point.latitude, point.longitude])
        }
      })
    } else if(locationBounds) {
      b.extend([locationBounds.northeast.lat, locationBounds.northeast.lng])
      b.extend([locationBounds.southwest.lat, locationBounds.southwest.lng])
    }
    return b
  }, [locationBounds, JSON.stringify(pins)])
  return {
    initBounds,
  }
}
