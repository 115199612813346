import { Component, Fragment } from 'react'
import Lightbox from 'react-images'
import VideoPopup from './VideoPopup'

const dslPlaceholder = {
  caption: 'PADI',
  src: `${window.staticPath}travel_dist/images/dsl_placeholder.jpg`,
}
const theme = {
  // container
  footerCaption: {
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: '16px',
  },
}

export default class HeroLightbox extends Component {
  constructor(props) {
    super(props)

    this.state = {
      currentImage: 0,
      isOpen: false,
    }
  }

  componentDidMount() {
    ['view-picture', 'operator-slider-wrapper'].forEach((item) => {
      let element = document.getElementById(item)
      if(element) {
        element.addEventListener('click', (e) => {
          if(e.target.id === 'view-video') {
            return
          }
          const currentImage = e.target.dataset.index || 0
          this.setState({
            isOpen: true,
            currentImage: +currentImage,
          })
        })
      }
    })
  }

  gotoPrevious = () => {
    this.setState({
      currentImage: this.state.currentImage - 1,
    })
  }

  gotoNext = () => {
    this.setState({
      currentImage: this.state.currentImage + 1,
    })
  }

  gotoImage = (index) => {
    this.setState({
      currentImage: index,
    })
  }

  closeLightbox = () => {
    this.setState({
      isOpen: false,
    })
  }

  render() {
    const { videos, photos } = this.props
    const { currentImage, isOpen } = this.state
    return (
      <Fragment>
        {videos && videos.length ? (
          <VideoPopup videos={this.props.videos} />
        ) : (
          ''
        )}
        <Lightbox
          images={photos.length ? photos : [dslPlaceholder]}
          currentImage={currentImage}
          isOpen={isOpen}
          onClickPrev={this.gotoPrevious}
          onClickNext={this.gotoNext}
          width={window.innerWidth * 0.9}
          onClickThumbnail={this.gotoImage}
          showThumbnails
          theme={theme}
          onClose={this.closeLightbox}
        />
      </Fragment>
    )
  }
}
