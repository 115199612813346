import { FormattedMessage } from 'react-intl'

export default function DiveCenterLevel({ info = {} }) {
  if(info.membershipLevel) {
    return info.membershipLevel
  }
  return info.is_five_star ? (
    <FormattedMessage
      id='padi_5_star_dc'
      defaultMessage='PADI 5 star dive center'
    />
  ) : (
    <FormattedMessage id='padi_dive_center' defaultMessage='PADI dive center' />
  )
}
