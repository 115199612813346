import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { pushToDataLayer } from 'gtm'
import { trackClick } from '../../gtm-events'
import { target } from 'utils'

const PopularCourses = ({ data }) => {
  const handleClick = () => {
    pushToDataLayer({
      event: 'productClick',
      ecommerce: {
        click: {
          actionField: { list: window.currentPageType },
          products: [data.impression],
        },
      },
    })

    trackClick({ regionIdentifier: 'popular-courses', link: data.url })
  }

  return (
    <div key={data.name} className='operator-item-wrapper'>
      <a
        className='operator-item-card operator-item-course bordered-element operator-item-card-container'
        href={data.url}
        target={target}
        onClick={handleClick}
      >
        <div className='img-wrapper'>
          <span
            style={{ backgroundImage: `url(${data.img})` }}
            className='img'
          />
        </div>
        <div className='operator-item-card__content operator-item-course__content'>
          {/* <div className='operator-item-card__status'>Beginner</div> */}
          <div className='operator-item-card__title operator-item-course__title'>
            {data.title}
          </div>
          <div className='operator-item-course__price'>
            {/* <span className='previous-price'>$195.00</span> */}
            <span className='current-price'>
              {data.price || (
                <FormattedMessage
                  id='contact_shop_for_price'
                  defaultMessage='Contact Dive Shop for Price'
                />
              )}
            </span>
          </div>
          <div
            className='operator-item-card__description operator-item-course__description'
            dangerouslySetInnerHTML={{ __html: data.description }}
          />
        </div>
      </a>
    </div>
  )
}

export default connect((state) => ({
  selectedCurrency: state.selectedCurrency,
}))(PopularCourses)
